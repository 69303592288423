<template>
  <header
    class="bg-black h-full sticky top-0 w-28 shrink-0 flex flex-col py-6 px-[0.875rem] print:hidden"
  >
    <img src="/mad051-logo.svg" alt="MAD 051" width="74" height="74" class="mx-auto" />

    <nav class="my-auto">
      <ul class="flex flex-col gap-2">
        <li v-for="(item, index) in menuItems" :key="index">
          <NuxtLink :to="item.to" class="sidebar-link" exact-active-class="is-active is-filled">
            <component :is="`svgo-${item.icon}`" class="mb-2" />
            <div>{{ item.label }}</div>
          </NuxtLink>
        </li>
      </ul>
    </nav>

    <NuxtLink
      :to="{ name: 'account' }"
      class="sidebar-link"
      exact-active-class="is-active is-filled"
    >
      <SvgoUser class="mb-2" />
      Profilo
    </NuxtLink>
  </header>
</template>

<script setup>
// Composables
const userStore = useUserStore()
const { t } = useContents()

// Data
const menuItems = [
  { to: { name: 'index' }, label: 'Home', icon: 'homepage' },
  { to: { name: 'new-moodboard' }, label: 'Nuova moodboard', icon: 'moodboard' },
  { to: { name: 'new-quote' }, label: 'Nuovo preventivo', icon: 'quote' },
  { to: { name: 'new-presentation' }, label: 'Nuova presentazione', icon: 'export' },
  { to: { name: 'projects' }, label: 'Progetti', icon: 'folder' }
]

// Computed
const user = computed(() => userStore.userData?.login?.user)
</script>

<style scoped>
.sidebar-link {
  @apply flex flex-col items-center justify-center text-center rounded-md hover:bg-accent h-[4.625rem] px-1 text-xs font-bold text-accent-light hover:text-black duration-300 ease-in-out;
}
.sidebar-link.is-active {
  @apply text-accent hover:text-black;
}
</style>
