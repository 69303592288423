<template>
  <div default-layout class="flex h-screen">
    <AppSidebar />
    <div default-layout-body class="flex flex-col w-full h-full overflow-x-hidden overflow-y-auto">
      <div
        default-layout-body-inner
        class="container flex-1 pt-6 px-gutter"
        :class="route.name === 'projects-project-moodboard' ? 'h-[100dvh]' : 'pb-12'"
      >
        <slot />
      </div>
      <div
        v-if="!['projects-project-moodboard'].includes(route.name)"
        class="container flex items-center justify-center pb-12 text-sm"
      >
        <NuxtLink to="/privacy-policy" class="underline">Privacy policy</NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup>
// Composables
const route = useRoute()
</script>

<style>
body {
  @apply bg-grey-dark text-white text-sm font-sans font-medium h-screen antialiased;
}

#__nuxt {
  @apply h-full;
}
</style>
